import React, { useState, useEffect } from 'react';
import { verify } from './api';
import QrReader from './QrReader';
import { useIntl } from 'react-intl';
import { ErrorCode } from './ErrorCode';

export default function ScanQr({ size, barHeight, onlineCode }) {
    const [loading, setLoading] = useState(false);
    const [delayed, setDelayed] = useState(false);
    const [legacyMode, setLegacyMode] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState();
    const { formatMessage, messages } = useIntl();

    const [message, setMessage] = useState(() => {
        if (!onlineCode) {
            return { level: 'error', text: formatMessage({ id: 'error.invalidUrl' }) };
        }

        return {
            text: formatMessage({ id: 'scan.locker' }),
        };
    });

    const onScan = (lockerUrl) => {
        if (!lockerUrl || !onlineCode || loading || delayed) {
            return;
        }

        let lockerCode = lockerUrl.split('/');
        lockerCode = lockerCode[lockerCode.length - 1];

        setLoading(true);
        setDelayed(true);
        setMessage({ level: 'info', text: formatMessage({ id: 'loading' }) });

        verify(onlineCode, lockerCode, (event, value) => {
            if (
                event === 'progress' &&
                ['Put parcel in', 'Take parcel out', 'Close the door'].includes(value)
            ) {
                setLoading(false);
                setMessage({
                    level: 'success',
                    text: formatMessage({ id: 'locker.open' }),
                });
            } else if (event === 'timeout') {
                setSecondsLeft(value);
            }
        })
            .then(() => {
                setMessage({ level: 'info', text: formatMessage({ id: 'thanks' }) });
            })
            .catch((error) => {
                console.error(error);

                if (error instanceof ErrorCode) {
                    const id = `error.code.${error.code}`;

                    if (messages[id]) {
                        setMessage({
                            level: 'error',
                            text: formatMessage({ id }),
                        });
                    } else {
                        setMessage({
                            level: 'error',
                            text: formatMessage({ id: 'error.code.unknown' }, { code: error.code }),
                        });
                    }

                    return;
                }

                setMessage({
                    level: 'error',
                    text: formatMessage({ id: 'error.unknown' }),
                });
            })
            .finally(() => {
                setLoading(false);
                setSecondsLeft(null);
                // Let's wait a little before we process the next scan
                setTimeout(() => setDelayed(false), 1500);
            });
    };

    const onError = (error) => {
        let message = error.message;
        if (message === 'Permission denied') {
            message = formatMessage({ id: 'error.cameraAccessDenied' });
        }

        setMessage({ level: 'error', text: message });
        //setLegacyMode(true);
    };

    return (
        <>
            <QrReader
                size={size}
                barHeight={barHeight}
                onScan={onScan}
                onError={onError}
                loading={loading}
                message={message}
                legacyMode={legacyMode}
            />
            {loading && <span className="loading-icon" />}
            {secondsLeft && <span className="seconds-left">{secondsLeft}</span>}
        </>
    );
}
