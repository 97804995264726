import React from 'react';
import QrCode from 'qrcode.react';
import { FormattedMessage } from 'react-intl';

export default function ShowQr({ size, barHeight, receiveCode }) {
    return (
        <>
            <div
                className="help-text"
                style={{
                    height: barHeight,
                    fontSize: size / 18,
                }}
            >
                <p className="dark"><FormattedMessage id="scan.robot" /></p>
            </div>
            <QrCode className="qr" size={size} value={receiveCode} />
            <div
                className="logo"
                style={{
                    height: barHeight,
                }}
            >
                <img src="/cleveron.svg" style={{ width: size / 3 }} alt="Cleveron logo" />
            </div>
        </>
    );
}
