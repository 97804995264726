import { useState, useEffect } from 'react';

export function useWindowSize() {
    const [size, setSize] = useState({});

    const updateSize = () => {
        setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    useEffect(() => {
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}
