import React from 'react';
import ShowQr from './ShowQr';
import ScanQr from './ScanQr';
import { useWindowSize } from './useWindowSize';
import translations from './translations.json';
import { IntlProvider } from 'react-intl';

function useSizes() {
    const { width, height } = useWindowSize();
    const size = Math.round(Math.min(height, width) * 0.7) || 0;
    const barHeight = (height - size) / 2 || 0;

    return { size, barHeight };
}

export default function App() {
    const code = window.location.pathname.split('/').slice(-1)[0];
    const { size, barHeight } = useSizes();

    return (
        <IntlProvider locale={navigator.language} messages={translations.et}>
            {!Number.isNaN(+code) && code.length === 6 ? (
                <ShowQr size={size} barHeight={barHeight} receiveCode={code} />
            ) : (
                <ScanQr size={size} barHeight={barHeight} onlineCode={code} />
            )}
        </IntlProvider>
    );
}
