import { ErrorCode } from './ErrorCode';

const env = process.env.NODE_ENV === 'development' ? process.env : window;
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PROGRESS_STEPS = [
    'Noop',
    'Wait',
    'Put parcel in',
    'Take parcel out',
    'Close the door',
    'Please confirm',
];

export async function verify(receiveCode, lockerCode, callback) {
    let resolve = () => {};
    let reject = () => {};
    let conTimeout = setTimeout(() => reject(new Error('Connection timed out')), 5000);

    const socket = new WebSocket(
        `${env.REACT_APP_WS_URL}/socket?place_uuid=${lockerCode}&online_code=${receiveCode}`
    );

    socket.onopen = () => {
        clearTimeout(conTimeout);
        console.log('[open] Connection established');
    };

    socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Data received from server', data);

        if (data.message_type === 'sessionProgress') {
            if (PROGRESS_STEPS[data.params.step_id] === 'Noop') {
                resolve();
            } else {
                callback('progress', PROGRESS_STEPS[data.params.step_id]);
            }
        } else if (data.message_type === 'sessionTimeoutCounter') {
            callback('timeout', data.secondsLeft);
        } else if (data.message_type === 'error') {
            reject(new ErrorCode(data.number, data.description));
        }
    };

    socket.onclose = (event) => {
        if (event.wasClean) {
            console.log(
                `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
            );
            resolve();
        } else {
            // e.g. server process killed or network down
            // event.code is usually 1006 in this case
            console.log('[close] Connection died');
            reject(new Error(event.reason));
        }
    };

    socket.onerror = (error) => {
        console.log(`[error] ${error.message}`);
        resolve(new Error(error.reason));
    };

    return new Promise((resolveFn, rejectFn) => {
        resolve = resolveFn;
        reject = rejectFn;
    })
        .then(async (res) => {
            await delay(1000);
            return res;
        })
        .catch(async (error) => {
            await delay(1000);
            throw error;
        })
        .finally(() => {
            socket.close();
        });
}
