import React from 'react';
import QrReaderBase from 'react-qr-reader';
import classnames from 'classnames';

export default class QrReader extends QrReaderBase {
    constructor(props) {
        super(props);
        this.previewEnabled = true;
        window.onScan = props.onScan;
    }

    shouldComponentUpdate() {
        return true;
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);
        //this.togglePreview(!nextProps.loading);
    }

    togglePreview = (enable) => {
        // To indicate to user that UI is in loading state
        if (this.els['preview'] && enable !== this.previewEnabled) {
            this.previewEnabled = enable;

            if (enable) {
                this.els['preview'].play();
            } else {
                this.els['preview'].pause();
            }
        }
    };

    render() {
        const {
            size,
            barHeight,
            loading,
            message,
            onImageLoad,
            legacyMode,
            showViewFinder,
        } = this.props;

        return (
            <div className={classnames('image-container', loading && 'loading')}>
                {!legacyMode && showViewFinder ? (
                    <div className="view-finder" style={{ height: size, width: size }} />
                ) : null}
                <div
                    className="help-text"
                    style={{
                        height: barHeight,
                        fontSize: size / 18,
                    }}
                >
                    <p className={message.level}>{message.text}</p>
                </div>
                <div
                    className="logo"
                    style={{
                        height: barHeight,
                    }}
                >
                    <img src="/cleveron.svg" style={{ width: size / 3 }} alt="Cleveron logo" />
                </div>
                {legacyMode ? (
                    <>
                        <input
                            type="file"
                            accept="image/*"
                            ref={this.setRefFactory('input')}
                            onChange={this.handleInputChange}
                        />
                        <img
                            className="preview img-preview"
                            ref={this.setRefFactory('img')}
                            onLoad={onImageLoad}
                            alt="Preview"
                        />
                    </>
                ) : (
                    <video
                        className="preview video-preview"
                        style={{ transform: this.state.mirrorVideo && 'scaleX(-1)' }}
                        ref={this.setRefFactory('preview')}
                    />
                )}
                <canvas hidden ref={this.setRefFactory('canvas')} />
            </div>
        );
    }
}
